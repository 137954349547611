import React, {useEffect, useMemo} from 'react';
import {Col, Form, Row} from 'react-bootstrap';
import {useGetDrawerSystemQuery} from 'components/customer/Product/store/drawerRunnerApi';
import {useFormikContext} from 'formik';
import {Drawer, DrawerKey} from 'components/customer/Product/entity/Drawer';
import {useAppContext, useProductContext} from 'contexts';
import SelectField from 'shared/components/SelectField';

interface DrawerTypeProps {
    isDrawerComponent?: boolean;
    runnerIndex?: number;
    fieldName: string;
    labelColSpan?: number;
}

export const DrawerType = ({
    runnerIndex,
    fieldName,
    isDrawerComponent = false,
    labelColSpan = 5,
}: DrawerTypeProps) => {
    const {getAvailableDrawers} = useProductContext<{
        getAvailableDrawers: () => number[];
    }>();
    const {data: drawerSystems} = useGetDrawerSystemQuery();
    const {values, setFieldValue} = useFormikContext<{
        drawers: Drawer[];
        cabinet_include_hardware: number;
        [key: string]: number | Drawer[];
    }>();

    const {userProfile} = useAppContext();

    const availableDrawerSystems = useMemo(() => {
        if (drawerSystems) {
            const availableDrawers = getAvailableDrawers();
            const isDrillOnly = values.cabinet_include_hardware == 0;

            return drawerSystems
                .filter((drawerSystem) =>
                    availableDrawers.includes(drawerSystem.id)
                )
                .filter(
                    (drawerSystem) =>
                        !userProfile.isAssemblyOnly ||
                        (!isDrillOnly && !drawerSystem.drillOnly)
                )
                .map((drawerSystem) => ({
                    ...drawerSystem,
                    ...{
                        disabled: !isDrillOnly && drawerSystem.drillOnly,
                    },
                }));
        }

        return [];
    }, [drawerSystems, values.cabinet_include_hardware, userProfile]);

    const selectHandler = (name: string, value: number) => {
        if (isDrawerComponent) {
            void setFieldValue(name, value);
        } else {
            void setFieldValue(`drawers[${runnerIndex}.${name}]`, value);
        }
    };

    useEffect(() => {
        if (isDrawerComponent) {
            void selectHandler(
                fieldName,
                Number(values[fieldName as DrawerKey])
            );
        } else {
            const drawer = values.drawers[Number(runnerIndex)];
            const drawerValue = drawer[fieldName as DrawerKey];
            void selectHandler(fieldName, Number(drawerValue));
        }
    }, [values.cabinet_include_hardware]);

    return (
        <Form.Group as={Row}>
            <Form.Label column md={labelColSpan} xs={12} style={{zIndex: 1}}>
                Type
                <span>*</span>
            </Form.Label>
            <Col md={7} xs={12}>
                <SelectField
                    name={
                        isDrawerComponent
                            ? fieldName
                            : `drawers[${runnerIndex}.${fieldName}]`
                    }
                    options={availableDrawerSystems?.map(
                        ({id, name, disabled}) => ({
                            label: name,
                            value: String(id),
                            disabled,
                        })
                    )}
                />
            </Col>
        </Form.Group>
    );
};
